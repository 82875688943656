import React from 'react'
import style from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Container = style.div`
  max-width: 48em;
  padding: 1em;
  display: flex;
  flex-direction: column;
`

const EventContainer = style.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: minmax(8em, auto) 1fr 1fr;
  grid-template-areas:
    "date name name"
    "info location location";
  @media (max-width: 480px) {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "name name"
      "date info"
      "location location";
    grid-row-gap: 1.25em;
    grid-column-gap: .5em;
    place-items: center;
    padding: .75em;
  }
  grid-gap: .25em;
  grid-column-gap: 1.25em;
  place-items: center;
  padding: 1em;
`

const Date = style.div`
  grid-area: date;
  color: dimgray;
  background: lightgray;
  border-radius: 5px;
  padding: .5em;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  justify-self: center;
`

const Name = style.div`
  grid-area: name;
  font-size: 2em;
  font-weight: 600;
  margin: .3em 0em .2em 0em;
  justify-self: start;
`

const Info = style.div`
  grid-area: info;
  color: dimgray;
  border-radius: 5px;
  padding: .5em;
  border: .1em solid lightgray;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  justify-self: center;
`

const Location = style.div`
  grid-area: location;
  color: gray;
  justify-self: start;
`

const Event = ({
  className,
  name,
  info,
  date,
  location
}) => (
  <EventContainer className={className}>
    <Date>{date}</Date>
    <Name>{name}</Name>
    <Info>{info}</Info>
    <Location>{location}</Location>
  </EventContainer>
)

const StyledEvent = style(Event)`
  background: whitesmoke;
  margin: 1em;
  border-radius: 5px;
  box-shadow: 0 4px 8px lightgray;
`

const Divider = style.div`
  height: 3em;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  place-content: center;
  color: dimgray;
  font-weight: 600;
  margin: 2em 0;
`
const Line = style.div`
  margin-top: .2em;
  height: .2em;
  width: 100%;
  border-radius: .05em;
  background-color: dimgray;
`

const EventsPage = () => {
  return (
    <StaticQuery query = {graphql`
      query EventsQuery {
        next: allEventsJson (
          sort: {fields: date, order: DESC},
          filter: { isFuture: { eq: true } }
        ) {
          edges {
            node {
              name
              info
              date(formatString: "DD/MM/YYYY")
              location
            }
          }
        }
        prev: allEventsJson (
          sort: {fields: date, order: DESC},
          filter: { isFuture: { eq: false } }
        ) {
          edges {
            node {
              name
              info
              date(formatString: "DD/MM/YYYY")
              location
            }
          }
        }
      }
    `}
    render = { (data) => {
      console.log(JSON.stringify(data))
      return (
        <Layout colorful={true}>
          <SEO title="Eventi"/>
          <Container>
            {data.next.edges.map(edge => edge.node).map(event => (
              <StyledEvent
                key={event.name}
                name={event.name}
                info={event.info}
                date={event.date}
                location={event.location}
              />
            ))}
            <Divider>
              <div>Eventi passati...</div>
              <Line/>
            </Divider>
            {data.prev.edges.map(edge => edge.node).map(event => (
              <StyledEvent
                key={event.name}
                name={event.name}
                info={event.info}
                date={event.date}
                location={event.location}
              />
            ))}
          </Container>
        </Layout>
      )
    }}
    />
  )
}

export default EventsPage
